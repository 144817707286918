/* global google */
// https://github.com/ubilabs/react-geosuggest/issues/337#issuecomment-316495694
/* ^ this is to stop the linter from killing the build before the script gets pulled in if building google map */
import React, {Component} from 'react'
// import style from './listingMap.module.scss'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
// import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel"

class LocationsMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			zoom: 10,
			bounds: null,
			map: null
		}

		this.filterItems = this.filterItems.bind(this);
		// this.onBoundsChange = this.onBoundsChange.bind(this);
	}
	filterItems(map) {
		const bounds = new google.maps.LatLngBounds();
		const items = this.props.data;
		items.map((item, i) => {
			const loc = new google.maps.LatLng(item.latitude, item.longitude);
			bounds.extend(loc);
		});
		const center = bounds.getCenter();
		const ne = bounds.getNorthEast()
		const sw = bounds.getSouthWest()
		const nw = { lat: ne.lat(), lng: sw.lng() }
		const se = { lat: sw.lat(), lng: ne.lng() }
		// bounds.fitBounds({
		// 	se: { lat: se.lat, lng: se.lng },
		// 	nw: { lat: nw.lat, lng: nw.lng }
		// });
		this.setState({ bounds, map, center });
	}
	render() {
		let markers = this.props.data;
		let { className } = this.props;
		className = (className||'').split(' ');
		className = className.filter((item) => item);
		const height = this.props.height || '315px';
		return (
			<LoadScript
				googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}
			>
				<GoogleMap
					zoom={this.state.zoom}
					center={this.state.center}
					mapContainerStyle={{width: '100%', height: height}}
					onLoad={map => {this.filterItems(map)}}
					onBoundsChange={map => {this.filterItems(map)}}
				>
					{markers.map((mark, index) => (
						<Marker key={index} position={{ lat: parseFloat(mark.latitude), lng: parseFloat(mark.longitude) }} />
					))}
				</GoogleMap>
			</LoadScript>
		)
	}
}

export default LocationsMap;